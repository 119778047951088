import Axios from 'axios';

import {
	ARCHIVE_LOADING,
	ALL_ARCHIVES_GET,
	ALL_ARCHIVES_ERROR,
	ARCHIVE_INFO_GET,
	ARCHIVE_INFO_ERROR,
	ARCHIVE_CREATE_LOADING,
	ARCHIVE_CREATE_SUCCESS,
	ARCHIVE_CREATE_ERROR,
	SHOW_ARCHIVE_REFERENCE,
	ARCHIVES_SEARCH_LOADING,
	ARCHIVES_SEARCH_GET,
	ARCHIVES_SEARCH_ERROR
} from './types';

// Utils
import create_query_params from '../utils/create_query_params';

export const archive_loading_true = () => dispatch => {
	dispatch({
		type: ARCHIVE_LOADING,
		payload: true
	})
}

export const archive_loading_false = () => dispatch => {
	dispatch({
		type: ARCHIVE_LOADING,
		payload: false
	})
}

export const get_all_archives = (filters) => dispatch => {
	dispatch(archive_loading_true());

	let url = process.env.REACT_APP_SERVER_URL + '/api/archive?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ALL_ARCHIVES_GET,
			payload: res.data
		});

		dispatch(archive_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_ARCHIVES_ERROR,
			payload: { type: 'all_archives', msg: err.message }
		});

		dispatch(archive_loading_false());
	})
}

export const get_archive_info = (archiveId) => dispatch => {
	dispatch(archive_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + `/api/archive/${archiveId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ARCHIVE_INFO_GET,
			payload: res.data
		});

		dispatch(archive_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ARCHIVE_INFO_ERROR,
			payload: { type: 'get_archive_info', msg: err.message }
		});

		dispatch(archive_loading_false());
	})
}

export const archive_create = (archiveBody, closeModalRef, filters) => dispatch => {
	dispatch({
		type: ARCHIVE_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/archive/backup';

	Axios.post(url, archiveBody)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ARCHIVE_CREATE_SUCCESS,
			payload: true
		});

		setTimeout(() => {
			closeModalRef.current.click();
			dispatch(get_all_archives(filters));
		}, 2400);

		setTimeout(() => {
			dispatch({
				type: ARCHIVE_CREATE_SUCCESS,
				payload: false
			})

			dispatch(archive_reference({ show: true, msg: res.data }));
		}, 2500);
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ARCHIVE_CREATE_LOADING,
			payload: false
		});

		dispatch({
			type: ARCHIVE_CREATE_ERROR,
			payload: { type: 'archive_create', msg: err.response.data?.archive || 'Error creating archive' }
		});
	})
}

export const archive_reference = (params) => dispatch => {
	dispatch({
		type: SHOW_ARCHIVE_REFERENCE,
		payload: params
	})
}

export const archives_search_loading_true = () => dispatch => {
	dispatch({
		type: ARCHIVES_SEARCH_LOADING,
		payload: true
	})
}

export const archives_search_loading_false = () => dispatch => {
	dispatch({
		type: ARCHIVES_SEARCH_LOADING,
		payload: false
	})
}

export const search_archives = (filters) => dispatch => {
	dispatch(archives_search_loading_true());

	let url = process.env.REACT_APP_SERVER_URL + '/api/archive/search?sort=true&';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ARCHIVES_SEARCH_GET,
			payload: res.data
		});

		dispatch(archives_search_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ARCHIVES_SEARCH_ERROR,
			payload: { type: 'search_archives', msg: err.message }
		});

		dispatch(archives_search_loading_false());
	})
}
