import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// React Bootstrap
import { Container, Form, Col, Row, Card } from 'react-bootstrap';

// Components
import LoadingSpinner from '../../../utils/LoadingSpinner';
import Service from './Service';
import ServiceLoad from './ServiceLoad';
import ServiceLoadData from './ServiceLoadData';

// Actions
import { get_load_services_info } from '../../../../actions/servicesActions';

const Services = () => {
	const dispatch = useDispatch();

	const { services_loading, load_services_info, services_errors } = useSelector(state => state.services);

	const [allServices, setAllServices] = useState([]);
	const [serviceSearch, setServiceSearch] = useState('');

	useEffect(() => {
		dispatch(get_load_services_info());
	}, []);

	useEffect(() => {
		setAllServices(load_services_info.services);
	}, [load_services_info]);

	useEffect(() => {
		const servicesFound = load_services_info.services.filter(service => service.service.name.toLowerCase().includes(serviceSearch.toLowerCase()));
		setAllServices(servicesFound);
	}, [serviceSearch]);

	return (
		<Container className='mt-3' style={{ paddingBottom: '5rem' }}>
			<h3 className='text-center'>Load</h3>

			{services_loading
				?	<LoadingSpinner loadingMsg='Loading' />
				:	services_errors.hasOwnProperty('load_services_info')
					?	<Card className='shadow mb-4 bg-body rounded card-border text-center'>
							<Card.Body>
								<i className='bi bi-exclamation-triangle-fill fs-1 text-warning'></i>
								<Card.Title>Error</Card.Title>
								<Card.Text>
									{services_errors.load_services_info.msg}
								</Card.Text>
							</Card.Body>
						</Card>
					: <Container>
						<ServiceLoad load={load_services_info.load} showLink={true}/>
						<hr/>

						<h2 className='text-center'>Data</h2>
						{Object.keys(load_services_info.data).map((key, idx) => (
							<ServiceLoadData key={idx} title={key} data={load_services_info.data[key]} />
						))}
						<hr/>
						
						<h2 className='text-center'>Services</h2>
						<p className='text-muted'>Total load services: {load_services_info.services.length}</p>
						<Form onSubmit={(e) => e.preventDefault()}>
							<Form.Group as={Row} className='mb-3' controlId='serviceName'>
								<Form.Label column sm='2' className='text-center'>
									<i className='bi bi-search me-2'></i> Service Name
								</Form.Label>
								<Col sm='10'>
									<Form.Control
										type='text'
										placeholder='Type service name'
										onChange={(e) => setServiceSearch(e.target.value)}
									/>
								</Col>
							</Form.Group>
						</Form>

						<Container className='custom-container'>
							{allServices.length > 0
								?	allServices.map((service, idx) => (
										<Service key={idx} service={service} />
									))
								: serviceSearch !== ''
									?	<p className='text-center'>No matches</p>
									: <p className='text-center'>No services found</p>
							}
						</Container>
					</Container>
			}
		</Container>
	);
}

export default Services;
