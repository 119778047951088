import React from 'react';

// Packages
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

// Components
import Header from './components/main/Header';
import Footer from './components/main/Footer';
import Authentication from './components/main/Authentication';
import NotFound from './components/main/NotFound';
import PrivateRoute from './router/PrivateRoute';
import LoadServices from './components/manager/load/services/Services';
import LoadServiceInfo from './components/manager/load/services/ServiceInfo';
import Workers from './components/manager/workers/Workers';
import WorkerInfo from './components/manager/workers/WorkerInfo';
import WorkerInstInfo from './components/manager/workersInstance/WorkerInstInfo';
import Helpers from './components/manager/helpers/Helpers';
import HelperInstInfo from './components/manager/helpersInstance/HelperInstInfo';
import HelperInfo from './components/manager/helpers/HelperInfo';
import LogsInfo from './components/manager/logs/LogsInfo';
import LoadInfo from './components/manager/load/main/LoadInfo';
import LoadDataInstances from './components/manager/load/main/LoadDataInstances';
import Messages from './components/manager/messages/Messages';
import Models from './components/manager/models/main/Models';
import ModelInfo from './components/manager/models/main/ModelInfo';
import Services from './components/manager/services/main/Services';
import ServiceInfo from './components/manager/services/main/ServiceInfo';
import ServiceInstanceInfo from './components/manager/services/instances/Info';
import RequestsDetails from './components/manager/services/requests/details/Details';
import Archive from './components/manager/archive/main/Archive';
import ArchiveInfo from './components/manager/archive/main/ArchiveInfo';

// Redux
import { Provider } from 'react-redux';
import store from './store';

// Actions
import { user_token_check } from './actions/authActions';

user_token_check (store);

const App = () => {
	return (
		<Provider store= { store }>
			<Router>
				<div className='App'>
					<Header/>

					<Routes>
						<Route path='/auth' element={ <Authentication /> } exact />

						<Route element={ <PrivateRoute /> } exact>
							{/* Load */}
							<Route path='/' element={ <LoadServices /> } exact />
							<Route path='/load/service/:service_id' element={ <LoadServiceInfo /> } exact />
							<Route path='/load/:load_id' element={ <LoadInfo /> } exact />
							<Route path='/load/:load_id/process/:process_id' element={ <LoadDataInstances /> } exact />

							{/* Services */}
							<Route path='/services' element={ <Services /> } exact />
							<Route path='/services/:service_id/details' element={ <ServiceInfo /> } exact />
							<Route path='/services/:service_id/instance/:instance_id' element={ <ServiceInstanceInfo /> } exact />

							{/* Requests */}
							<Route path='/requests/:request_id/details' element={ <RequestsDetails /> } exact />

							{/* Workers */}
							<Route path='/workers' element={ <Workers /> } exact />
							<Route path='/worker/:worker_id/resource/:resource_id' element={ <WorkerInfo /> } exact />
							<Route path='/worker/:worker_id/resource/:resource_id/instance/:instance_id' element={ <WorkerInstInfo /> } exact />

							{/* Helpers */}
							<Route path='/helpers' element={ <Helpers /> } exact />
							<Route path='/helper/:helper_id/resource/:resource_id' element={ <HelperInfo /> } exact />
							<Route path='/helper/:helper_id/resource/:resource_id/instance/:instance_id' element={ <HelperInstInfo /> } exact />

							{/* Logs */}
							<Route path='/worker/:worker_id/instance/:instance_id/job/:job_id' element={ <LogsInfo /> } exact />
							<Route path='/helper/:helper_id/instance/:instance_id/job/:job_id' element={ <LogsInfo /> } exact />

							{/* Messages */}
							<Route path='/messages' element={ <Messages /> } exact />

							{/* Models */}
							<Route path='/models' element={ <Models /> } exact />
							<Route path='/models/:model_id/info' element={ <ModelInfo /> } exact />

							{/* Archive */}
							<Route path='/archive' element={ <Archive /> } exact />
							<Route path='/archive/:archive_id/info' element={ <ArchiveInfo /> } exact />
						</Route>

						{/* Other */}
						<Route path='*' element={ <NotFound /> } />
					</Routes>

					<Footer/>
				</div>
			</Router>
		</Provider>
	);
}

export default App;
