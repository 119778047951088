import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';

// Components
import Alert from '../../../utils/Alert';
import SelectButton from '../../../utils/SelectButton';

// Actions
import { get_cycles, get_modules, get_workspaces } from '../../../../actions/organizationActions';

// Utils
import errorExists from '../../../../utils/errorExists';

// Icons
import checkAnimation from '../../../../static/icons/checkAnimation.json';
import { archive_create } from '../../../../actions/archiveActions';

const ArchiveForm = (props) => {
	const { show, onHide, orgFilters } = props;

	const dispatch = useDispatch();

	const { organizations, workspaces, modules, cycles } = useSelector(state => state.organization);

	const { archive_create_loading, archive_create_success, archives_errors } = useSelector(state => state.archive);
	
	const initialAlert = { display: false, type: '', msg: '' };
	const [alert, setAlert] = useState(initialAlert);
	
	const [archiveInfo, setArchiveInfo] = useState({ organization: '', workspace: '', module: '', cycle: '' });

	const closeModalRef = useRef();
	const lottieRef	= useRef();

	useEffect(() => {
		setArchiveInfo({
			organization: orgFilters.organization,
			workspace: orgFilters.workspace,
			module: orgFilters.module,
			cycle: ''
		})
	}, [orgFilters]);	

	useEffect(() => {
		if (archiveInfo.organization !== '') {
			dispatch(get_workspaces({ organization: archiveInfo.organization }));
			dispatch(get_modules({ organization: archiveInfo.organization }));
		}

		if (archiveInfo.workspace !== '') {
			if (archiveInfo.module !== '') {
				dispatch(get_cycles({ workspace: archiveInfo.workspace, module: archiveInfo.module }));
			}
		}
	}, [archiveInfo]);

	const closeModal = () => {
		onHide();
		setAlert(initialAlert);
		setArchiveInfo({ organization: '', workspace: '', module: '', cycle: '' });
	}

	const validateArchiveInfo = archiveInfo.name === '' || archiveInfo.description === '';

	const createArchive = () => {		
		if (validateArchiveInfo) {
			setAlert({ display: true, type: 'danger', msg: 'Missing fields!' });
			return;
		}
		setAlert(initialAlert);

		dispatch(archive_create({ cycle: archiveInfo.cycle }, closeModalRef, orgFilters));
	}

	return (
		<Modal
			show={show}
			onHide={closeModal}
			backdrop='static'
			keyboard={false}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Create Archive</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{alert.display && <Alert type={alert.type} msg={alert.msg} />}
				
				{errorExists(archives_errors, 'archive_create') &&
					<Alert type='danger' msg={archives_errors['archive_create'].msg} />
				}

				<Form>
					{orgFilters.organization === '' &&
						<Container className='mb-3 p-0' fluid>
							<p className='mb-2'>Organization</p>
							<SelectButton
								options={organizations}
								name='organization'
								value={archiveInfo.organization}
								onChange={(e) => setArchiveInfo({ ...archiveInfo, organization: e ? e.value : null })}
							/>
						</Container>
					}
					{orgFilters.workspace === '' &&
						<Container className='mb-3 p-0' fluid>
							<p className='mb-2'>Workspace</p>
							<SelectButton
								options={workspaces}
								name='workspace'
								value={archiveInfo.workspace}
								onChange={(e) => setArchiveInfo({ ...archiveInfo, workspace: e ? e.value : null })}
							/>
						</Container>
					}
					{orgFilters.module === '' &&
						<Container className='mb-3 p-0' fluid>
							<p className='mb-2'>Module</p>
							<SelectButton
								options={modules}
								name='module'
								value={archiveInfo.module}
								onChange={(e) => setArchiveInfo({ ...archiveInfo, module: e ? e.value : null })}
							/>
						</Container>
					}
					<Container className='mb-3 p-0' fluid>
						<p className='mb-2'>Cycle</p>
						<SelectButton
							options={cycles}
							name='cycle'
							value={archiveInfo.cycle}
							onChange={(e) => setArchiveInfo({ ...archiveInfo, cycle: e ? e.value : null })}
						/>
					</Container>
				</Form>
			</Modal.Body>
			<Modal.Footer>
			<Button variant='outline-secondary' onClick={closeModal} ref={closeModalRef}>Cancel</Button>
				<Button 
					className={archive_create_success ? 'success-btn' : 'submit-btn'}
					onClick={createArchive}
				>
					{archive_create_success
						?	<Lottie
								loop={false}
								lottieRef={lottieRef}
								animationData={checkAnimation}
								style={{ height: '24px' }}
							/>
						:	archive_create_loading
							?	<Fragment>
									<Spinner className='me-2' as='span' animation='border' size='sm' role='status' aria-hidden='true' />
									Creating
								</Fragment>
							:	'Create'
					}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

ArchiveForm.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	orgFilters: PropTypes.object.isRequired
}

export default ArchiveForm;
