import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import { Container, Col } from 'react-bootstrap';

// Actions
import { get_archive_info } from '../../../../actions/archiveActions';

// Utils
import isEmpty from '../../../../utils/isEmpty';
import { epochToLocalDate } from '../../../../utils/dateConverter';

const ArchiveInfo = () => {
	const { archive_id } = useParams();

	const dispatch = useDispatch();

	const { archive_loading, archive_info } = useSelector(state => state.archive);

	const [archiveInfoParsed, setArchiveInfoParsed] = useState(null);

	useEffect(() => {
		dispatch(get_archive_info(archive_id));
	}, [archive_id]);

	useEffect(() => {
		if (!isEmpty(archive_info)) {
			const archiveInfoCopy = { ...archive_info };

			if (archiveInfoCopy?.date?.$date) {
				archiveInfoCopy.date.$date = epochToLocalDate(archiveInfoCopy.date.$date);
			}

			setArchiveInfoParsed(JSON.stringify(archiveInfoCopy, null, 2));
		}
	}, [archive_info]);

	return (
		<Container className='mt-3 custom-p-container'>
			<h2 className='text-center'>Archive Info</h2>
			{archive_loading
				?	<div>Loading...</div>
				:	<Container className='terminal-container'>
						<Container className='wrapper-container px-0'>
							<Col className='title mt-2'>
								<h5 style={{ textAlign: 'center', color: '#a2a2a2' }}><pre>percepthor@linux</pre></h5>
							</Col>
							<Col className='icons-btns mt-2 px-0'>
								<Container className='d-flex align-items-center justify-content-end'>
									<i className='bi bi-circle-fill mx-1' style={{ color: '#efcf84' }}></i>
									<i className='bi bi-circle-fill mx-1' style={{ color: '#94c68b' }}></i>
									<i className='bi bi-circle-fill mx-1' style={{ color: '#cf5062' }}></i>
								</Container>
							</Col>
						</Container>
						<Container className='terminal terminal-scrollbar'>
							<pre style={{ color: '#eeeeee', width: 'fit-content', cursor: 'pointer' }}>
								{archiveInfoParsed}
							</pre>
						</Container>
					</Container>
			}
		</Container>
	)
}

export default ArchiveInfo;
