import { createSelectOptions } from './createOptions';

import {
	getArchiveResourceDesc,	
	getArchiveStatusDesc
} from './getStatusDesc';

const ARCHIVE_RESOURCE_NONE = 0
const ARCHIVE_RESOURCE_PAYLOADS = 1
const ARCHIVE_RESOURCE_ISSUES = 2
const ARCHIVE_RESOURCE_OTHER = 3

export const archiveResourceOptions = createSelectOptions([
	getArchiveResourceDesc(ARCHIVE_RESOURCE_NONE),
	getArchiveResourceDesc(ARCHIVE_RESOURCE_PAYLOADS),
	getArchiveResourceDesc(ARCHIVE_RESOURCE_ISSUES),
	getArchiveResourceDesc(ARCHIVE_RESOURCE_OTHER)
]);

const ARCHIVE_STATUS_NONE = 0
const ARCHIVE_STATUS_CREATED = 1
const ARCHIVE_STATUS_PROGRESS = 2
const ARCHIVE_STATUS_INCOMPLETE = 3
const ARCHIVE_STATUS_COMPLETED = 4
const ARCHIVE_STATUS_REMOVED = 5

export const archiveStatusOptions = createSelectOptions([
	getArchiveStatusDesc(ARCHIVE_STATUS_NONE),
	getArchiveStatusDesc(ARCHIVE_STATUS_CREATED),
	getArchiveStatusDesc(ARCHIVE_STATUS_PROGRESS),
	getArchiveStatusDesc(ARCHIVE_STATUS_INCOMPLETE),
	getArchiveStatusDesc(ARCHIVE_STATUS_COMPLETED),
	getArchiveStatusDesc(ARCHIVE_STATUS_REMOVED)
]);
