import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// React Bootstrap
import { Button, Col, Container, Row, Spinner, Tab, Tabs } from 'react-bootstrap';

// Components
import ArchiveForm from './ArchiveForm';
import ArchiveReference from './ArchiveReference';
import ArchiveTable from './ArchiveTable';
import SelectButton from '../../../utils/SelectButton';
import ToastAlert from '../../../utils/ToastAlert';
import SearchArchives from '../search/SearchArchives';

// Actions
import { get_organizations, get_workspaces, get_modules, get_cycles } from '../../../../actions/organizationActions';
import { get_all_archives, search_archives } from '../../../../actions/archiveActions';

// Utils
import { archiveResourceOptions, archiveStatusOptions } from '../../../../utils/selectOptions';

// Hooks
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const Archive = () => {
	const dispatch = useDispatch();

	const { user } = useSelector(state => state.auth);

	const { organizations, workspaces, modules, cycles } = useSelector(state => state.organization);

	const { archive_loading, all_archives } = useSelector(state => state.archive);

	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '', cycle: '' });
	const [modalShow, setModalShow] = useState(false);
	const [archiveFilters, setArchiveFilters] = useState({ resource: null, status: null });
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(9);
	const [limit] = useState(10);

	useEffect(() => {
		dispatch(get_organizations());
	}, []);

	useEffect(() => {
		let _organization = '';

		if (organizations.length <= 1) {
			_organization = user.organization;
		}
		else {
			_organization = localStorage.getItem('organization');
		}
		
		setOrgFilters({
			...orgFilters,
			organization: _organization,
			workspace: localStorage.getItem('workspace') || '',
			module: localStorage.getItem('module') || ''
		});
	}, [organizations]);

	useDidMountEffect(() => {
		if (orgFilters.organization !== '') {
			dispatch(get_workspaces({ organization: orgFilters.organization }));
		}
	}, [orgFilters.organization]);

	useDidMountEffect(() => {
		if (orgFilters.organization !== '') {
			dispatch(get_modules({ organization: orgFilters.organization }));
		}

		if (orgFilters.workspace !== '') {
			if (orgFilters.module !== '') {
				dispatch(get_cycles({ workspace: orgFilters.workspace, module: orgFilters.module }));
			}
		}
	}, [orgFilters]);

	useDidMountEffect(() => {
		const filters = {
			...(orgFilters.organization !== '' && { organization: orgFilters.organization }),
			...(orgFilters.workspace !== '' && { workspace: orgFilters.workspace }),
			...(orgFilters.module !== '' && { module: orgFilters.module }),
			...(archiveFilters.resource !== null && { resource: archiveFilters.resource }),
			...(archiveFilters.status !== null && { status: archiveFilters.status }),
			skip: start,
			limit
		}
		dispatch(get_all_archives(filters));
	}, [orgFilters, start]);

	useDidMountEffect(() => {
		const searchFilters = {
			...(orgFilters.organization !== '' && { organization: orgFilters.organization }),
			...(orgFilters.workspace !== '' && { workspace: orgFilters.workspace }),
			...(orgFilters.module !== '' && { module: orgFilters.module })
		}
		dispatch(search_archives(searchFilters));
	}, [orgFilters]);

	const onChangeOrgFilters = (e, name) => {
		let filters = {...orgFilters};
		let keys = Object.keys(filters);
		
		let idx = keys.indexOf(name);
		for (let index = idx; index < keys.length; index++) {
			const key = keys[index];
			filters[key] = '';
			localStorage.setItem(key, '');
		}

		if (e !== null){
			filters[name] = e.value;
			localStorage.setItem(name, e.value);
		}

		setOrgFilters(filters);
	}

	const onHide = () => {
		setModalShow(false);
	}

	const createArchive = () => {
		setModalShow(true);
	}

	return (
		<Container className='mt-3 pb-5'>
			<ToastAlert />

			<ArchiveForm
				show={modalShow}
				onHide={onHide}
				orgFilters={orgFilters}
			/>

			<ArchiveReference />

			<Container className='wrapper-container px-0 mb-3'>
				<Col className='title'>
					<h3 className='text-center'>Archive</h3>
				</Col>
				<Col className='icons-btns'>
					<Container className='d-flex align-items-center justify-content-end px-0'>
						<Button className='submit-btn' onClick={createArchive}>
							<strong>Create</strong>
						</Button>
					</Container>
				</Col>
			</Container>

			<Row className='mb-3'>
				<Col>
					<p className='text-center mb-2'>Organization</p>
					<SelectButton
						options={organizations}
						name='organization'
						value={orgFilters.organization ? orgFilters.organization : null}
						onChange={onChangeOrgFilters}
					/>
					</Col>
				<Col>
					<p className='text-center mb-2'>Workspace</p>
					<SelectButton
						options={workspaces}
						name='workspace'
						value={orgFilters.workspace ? orgFilters.workspace : null}
						onChange={onChangeOrgFilters}
					/>
				</Col>
				<Col>
					<p className='text-center mb-2'>Module</p>
					<SelectButton
						options={modules}
						name='module'
						value={orgFilters.module ? orgFilters.module : null}
						onChange={onChangeOrgFilters}
					/>
				</Col>
				<Col>
					<p className='text-center mb-2'>Cycle</p>
					<SelectButton
						options={cycles}
						name='cycle'
						value={orgFilters.cycle ? orgFilters.cycle : null}
						onChange={onChangeOrgFilters}
					/>
				</Col>
			</Row>
			<Tabs defaultActiveKey='all' id='archive-tabs' className='mb-3 pt-5' justify>
				<Tab eventKey='all' title='All Archives'>
					<Row className='mb-3'>
						<Col>
							<p className='text-center mb-2'>Resource</p>
							<SelectButton
								options={archiveResourceOptions}
								name='resource'
								value={archiveFilters.resource}
								onChange={(e) => setArchiveFilters({ ...archiveFilters, resource: e !== null ? e.value : null })}
							/>
							</Col>
						<Col>
							<p className='text-center mb-2'>Status</p>
							<SelectButton
								options={archiveStatusOptions}
								name='status'
								value={archiveFilters.status}
								onChange={(e) => setArchiveFilters({ ...archiveFilters, status: e !== null ? e.value : null })}
							/>
						</Col>
					</Row>
					<p className='text-muted'>Total archives: {all_archives.count}</p>
					{archive_loading
						?	<Container className='d-flex align-items-center justify-content-center' style={{ height: '5rem' }}>
								<Spinner animation='border' style={{ width: '2.5rem', height: '2.5rem', color: '#004982' }} />
							</Container>
						:	all_archives.archives.length > 0
							?	<ArchiveTable
									tableLoading={archive_loading}
									dataCount={all_archives.count}
									tableData={all_archives.archives}
									start={start}
									setStart={setStart}
									end={end}
									setEnd={setEnd}
									limit={limit}
								/>
							: <p className='text-center'>No archives found</p>
					}
				</Tab>
				<Tab eventKey='search' title='Search Archives'>
					<SearchArchives />
				</Tab>
			</Tabs>
		</Container>
	);
}

export default Archive;
