const createSpeedOptions = () => {
	const workerSpeed = ['General', 'Common', 'Priority', 'Background', 'Backup'];
	let speedOptions = [];

	workerSpeed.forEach((option, idx) => {
		speedOptions.push({ name: option, value: (idx + 1) });
	});

	return speedOptions;
}

const createSelectOptions = (options) => {
	let selectOptions = [];

	options.forEach((option, idx) => {
		selectOptions.push({ name: option, value: idx });
	});

	return selectOptions;
}

export { createSpeedOptions, createSelectOptions };

