import React from 'react';

// Packages
import { Card, Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

// Components
import ArchiveResource from './ArchiveResource';
import StatusBadge from '../../../utils/StatusBadge';

// Utils
import { epochToLocalDate } from '../../../../utils/dateConverter';
import { getCycleStatusDesc } from '../../../../utils/getStatusDesc';

const ArchiveInfo = (props) => {
	const { archiveInfo } = props;

	return (
		<Card className='shadow mb-5 bg-body rounded card-border'>
			<Card.Body>
				<Card.Title className='text-center' as='h5'>{archiveInfo?.name}</Card.Title>
				<Card.Subtitle className='mb-2 text-center text-muted' as='p'>{archiveInfo?.description}</Card.Subtitle>
				<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {archiveInfo?._id?.$oid}</Card.Subtitle>
				<hr/>						
				<Container className='p-0'>
					<Row>
						<Col className='text-center'>
							<strong>Creation Date:</strong> {epochToLocalDate(archiveInfo?.date.$date)}
						</Col>
						<Col className='text-center'>
							<strong>Start:</strong> {epochToLocalDate(archiveInfo?.start.$date)}
						</Col>
						<Col className='text-center'>
							<strong>End:</strong> {epochToLocalDate(archiveInfo?.end.$date)}
						</Col>
					</Row>
					<hr/>
					<Row>
						<Col className='text-center'>
							<strong>Status:</strong> <StatusBadge status={getCycleStatusDesc(archiveInfo?.status)} />
						</Col>
					</Row>
					<hr/>
					<Row>
						<Col>
							<ArchiveResource
								resourceType={1}
								resource={archiveInfo?.archives[1]}
							/>
						</Col>
						<Col>
							<ArchiveResource
								resourceType={2}
								resource={archiveInfo?.archives[2]}
							/>
						</Col>
					</Row>
				</Container>
			</Card.Body>
		</Card>
	)
}

ArchiveInfo.propTypes = {
	archiveInfo: PropTypes.object.isRequired
}

export default ArchiveInfo;
