import React from 'react';
import { useSelector } from 'react-redux';

// Packages
import { Container, Spinner } from 'react-bootstrap';

// Components
import ArchiveInfo from './ArchiveInfo';

const SearchArchives = () => {
	const { archives_search_loading, archives } = useSelector(state => state.archive);

	return (
		<div>
			{archives_search_loading
				?	<Container className='d-flex align-items-center justify-content-center' style={{ height: '5rem' }}>
						<Spinner animation='border' style={{ width: '2.5rem', height: '2.5rem', color: '#004982' }} />
					</Container>
				:	archives.length > 0
					?	archives.map((archive, index) => (
							<ArchiveInfo
								key={index}
								archiveInfo={archive}
							/>
						))
					:	<p>No archives found</p>
			}
		</div>
	)
}

export default SearchArchives;
