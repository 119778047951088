import React from 'react';

// Packages
import { Container, Spinner, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

// Components
import TablePagination from '../../../utils/TablePagination';
import StatusBadge from '../../../utils/StatusBadge';

// Utils
import { epochToLocalDate } from '../../../../utils/dateConverter';
import { getArchiveResourceDesc, getArchiveStatusDesc } from '../../../../utils/getStatusDesc';

const ArchiveTable = props => {
	const { tableLoading, dataCount, tableData, start, setStart, end, setEnd, limit } = props;

	const nPages = Math.ceil(dataCount / limit);

	return (
		<div>
			<Table responsive>
				<thead style={{backgroundColor: '#e7f1ff'}}>
					<tr>
						<th>Id</th>
						<th>Storage</th>
						<th>Cycle</th>
						<th>Resource</th>
						<th>Status</th>
						<th>Date</th>
					</tr>
				</thead>
				<tbody>
					{tableLoading 
						?	<tr>
								<td className='text-center' colSpan={9}>
									<Container className='d-flex align-items-center justify-content-center' style={{ height: '5rem' }}>
										<Spinner animation='border' style={{ width: '2.5rem', height: '2.5rem', color: '#004982' }} />
									</Container>
								</td>
							</tr>
						:	tableData.length > 0
							?	tableData.map((archive) => (
									<tr
										key={archive?._id?.$oid}
										style={{ cursor: 'pointer' }}
										onClick={() => window.open(`/archive/${archive?._id?.$oid}/info`, '_self')}
									>
										<td>{archive?._id?.$oid}</td>
										<td>{archive?.storage?.alias}</td>
										<td>{archive?.cycle?.name}</td>
										<td>{archive.resource !== undefined ? getArchiveResourceDesc(archive.resource) : getArchiveResourceDesc(1)}</td>
										<td><StatusBadge status={getArchiveStatusDesc(archive?.status)} /></td>
										<td>{epochToLocalDate(archive?.date?.$date)}</td>
									</tr>
								))
							:	<tr>
									<td className='text-center' colSpan={9}>No archives</td>
								</tr>
					}
				</tbody>
			</Table>
			<TablePagination
				count={dataCount}
				arrayLength={tableData.length}
				nPages={nPages}
				start={start}
				setStart={setStart}
				end={end}
				setEnd={setEnd}
				limit={limit}
			/>
		</div>
	)
}

ArchiveTable.propTypes = {
	tableLoading: PropTypes.bool.isRequired,
	dataCount: PropTypes.number.isRequired,
	tableData: PropTypes.array.isRequired,
	start: PropTypes.number.isRequired,
	setStart: PropTypes.func.isRequired,
	end: PropTypes.number.isRequired,
	setEnd: PropTypes.func.isRequired,
	limit: PropTypes.number.isRequired
}

export default ArchiveTable;
