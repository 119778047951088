import {
	ARCHIVE_LOADING,
	ALL_ARCHIVES_GET,
	ALL_ARCHIVES_ERROR,
	ARCHIVE_INFO_GET,
	ARCHIVE_INFO_ERROR,
	ARCHIVE_CREATE_LOADING,
	ARCHIVE_CREATE_SUCCESS,
	ARCHIVE_CREATE_ERROR,
	SHOW_ARCHIVE_REFERENCE,
	ARCHIVES_SEARCH_LOADING,
	ARCHIVES_SEARCH_GET,
	ARCHIVES_SEARCH_ERROR
} from '../actions/types';

const initialState = {
	archive_loading: false,
	all_archives: { count: 0, archives: [] },
	archive_info: {},
	archive_create_loading: false,
	archive_create_success: false,
	show_archive_reference: { show: false, msg: '' },
	archives_search_loading: false,
	archives: [],
	archives_errors: {}
}

export default function archiveReducer (state = initialState, action){
	switch (action.type){
		case ARCHIVE_LOADING:
			return {
				...state,
				archive_loading: action.payload
			}
		case ALL_ARCHIVES_GET:
			return {
				...state,
				all_archives: {
					count: action.payload.count,
					archives: action.payload.archives
				}
			}
		case ARCHIVE_INFO_GET:
			return {
				...state,
				archive_info: action.payload
			}
		case ARCHIVE_CREATE_LOADING:
			return {
				...state,
				archive_create_loading: action.payload
			}
		case ARCHIVE_CREATE_SUCCESS:
			return {
				...state,
				archive_create_success: action.payload
			}
		case SHOW_ARCHIVE_REFERENCE:
			return {
				...state,
				show_archive_reference: action.payload
			}
		case ARCHIVES_SEARCH_LOADING:
			return {
				...state,
				archives_search_loading: action.payload
			}
		case ARCHIVES_SEARCH_GET:
			return {
				...state,
				archives: action.payload
			}
		case ALL_ARCHIVES_ERROR:
		case ARCHIVE_INFO_ERROR:
		case ARCHIVE_CREATE_ERROR:
		case ARCHIVES_SEARCH_ERROR:
			return {
				...state,
				archives_errors: {
					...state.archives_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
