import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Modal } from 'react-bootstrap';

// Actions
import { archive_reference } from '../../../../actions/archiveActions';

const ArchiveReference = () => {
	const dispatch = useDispatch();

	const {show_archive_reference } = useSelector(state => state.archive);

	const closeModal = () => {
		dispatch(archive_reference({ show: false, msg: '' }));
	}

	return (
		<Modal
			show={show_archive_reference.show}
			onHide={closeModal}
			backdrop='static'
			keyboard={false}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Archive Job Reference</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert variant='success'>
					<pre>
						{JSON.stringify(show_archive_reference.msg, null, 2)}
					</pre>
				</Alert>
			</Modal.Body>
		</Modal>
	)
}

export default ArchiveReference;
