import React from 'react';

// Packages
import { Card, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';

// Components
import StatusBadge from '../../../utils/StatusBadge';

// Utils
import { getArchiveResourceDesc, getArchiveStatusDesc } from '../../../../utils/getStatusDesc';
import { epochToLocalDate } from '../../../../utils/dateConverter';

const ArchiveResource = (props) => {
	const { resourceType, resource } = props;

	return (
		<Container className='mt-3'>
			<h5 className='text-center'>{getArchiveResourceDesc(resourceType)}</h5>

			<Card className='mb-4'>
				{resource !== null
					?	<Card.Body>
							{/* <Card.Title as='h6'>title</Card.Title>
							<Card.Subtitle className='mb-2 text-muted' as='p'>description</Card.Subtitle> */}
							<p className='m-0 p-0'><strong>Id:</strong> {resource?._id?.$oid}</p>
							<p className='m-0 p-0'><strong>Organization:</strong> {resource?.organization?.$oid}</p>
							<p className='m-0 p-0'><strong>Workspace:</strong> {resource?.workspace?.$oid}</p>
							<p className='m-0 p-0'><strong>Storage:</strong> {resource?.storage?.$oid}</p>
							<p className='m-0 p-0'><strong>Status:</strong> <StatusBadge status={getArchiveStatusDesc(resource?.status)} /></p>
							<p className='m-0 p-0'><strong>Date:</strong> {epochToLocalDate(resource?.date?.$date)}</p>
						</Card.Body>
					:	<Card.Body className='text-center'>
							<i className={`bi bi-exclamation-triangle-fill text-warning`} style={{ fontSize: '2rem' }}></i>
							<p>Archive resource is null.</p>
						</Card.Body>
				}
			</Card>
		</Container>
	)
}

ArchiveResource.propTypes = {
	resourceType: PropTypes.number.isRequired,
	resource: PropTypes.object
}

export default ArchiveResource;
